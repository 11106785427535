<template>
  <div class="omni-spy d-flex flex-column">
    <!-- <h1>Omni Business . Agentes</h1> -->
    <!-- <PageHeader title="Omni Business" subtitle="Agentes" /> -->
    <PageHeader title="Chat" subtitle="Agentes" />
    <div class="omni-main d-flex flex-fill">
      <div class="card omni-agents">
        <div class="d-flex flex-fill">
          <div class="omni-agents-list d-flex flex-column">
            <header class="d-flex align-items-center">Agentes</header>
            <div class="flex-fill">
              <div class="omni-container-overflow">
                <!-- <template v-for="(item, index) in [...new Array(100)]"> -->
                <div class="omni-status" v-if="availableAgents.length">
                  Ativos ({{availableAgents.length}})
                </div>
                <template v-for="(agent, index) in availableAgents">
                  <div class="omni-item" :key="`availableAgents:${index}`">
                    <button class="sub" :class="{ active: index === currentAgent }" @click="chooseAgent(agent)">
                      <div class="d-flex align-items-center">
                        <div class="profile">
                          <div class="notify bg-success">{{agent.rooms || ''}}</div>
                          <span class="material-icons-outlined placeholder">person</span>
                        </div>
                        <div class="name p-2">{{agent.name}}</div>
                        <time class="time-agent">
                          {{agent.lastUpdate | relativeDate}}
                          <span class="material-icons-outlined">schedule</span>
                        </time>
                      </div>
                    </button>
                  </div>
                </template>
                <div class="omni-status" v-if="notAvailableAgents.length">
                  Indisponiveis ({{notAvailableAgents.length}})
                </div>
                <template v-for="(agent, index) in notAvailableAgents">
                  <div class="omni-item" :key="`notAvailableAgents:${index}`">
                    <button class="sub" :class="{ active: agent === currentAgent }" @click="chooseAgent(agent)">
                      <div class="d-flex align-items-center">
                        <div class="profile">
                          <div
                            class="notify"
                            :class="{
                              'bg-success': agent.status === 'AVAILABLE',
                              'bg-warning': agent.status === 'UNAVAILABLE',
                              'bg-danger': agent.status === 'OFFLINE' || !agent.status,
                              'bg-info': (
                                agent.status === 'SHORT_BREAK' ||
                                agent.status === 'BREAK' ||
                                agent.status === 'LONG_BREAK'
                              ),
                            }">
                              <template v-if="agent.status === 'OFFLINE' || !agent.status">
                                <span class="material-icons-outlined">remove</span>
                              </template>
                              <template v-else>
                                <span class="material-icons-outlined">schedule</span>
                              </template>
                            </div>
                          <span class="material-icons-outlined placeholder">person</span>
                        </div>
                        <div class="name p-2 trimmed-text">{{agent.name}}</div>
                        <time class="time-agent">
                          {{agent.lastUpdate | relativeDate}}
                          <span class="material-icons-outlined">schedule</span>
                        </time>
                      </div>
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="omni-agent flex-fill">
            <div class="omni-container-overflow p-4">
              <template v-if="currentAgent !== null">
                <header class="profile d-flex">
                  <div class="photo">
                    <span class="material-icons-outlined">person</span>
                  </div>
                  <div class="info">
                    <h4 class="trimmed-text">{{currentAgent.name}}</h4>
                    <!-- <p>TewOne</p>
                    <p>Marketing</p> -->
                  </div>
                </header>
                <h4>Informações</h4>
                <!-- <pre>{{currentAgent}}</pre> -->
                <ul class="meta-info">
                  <li v-if="currentAgent.phone">
                    <span class="material-icons-outlined">phone</span>
                    {{currentAgent.phone}}
                  </li>
                  <li v-if="currentAgent.email">
                    <span class="material-icons-outlined">mail</span>
                    {{currentAgent.email}}
                  </li>
                  <!-- <li>
                    <span class="material-icons-outlined">place</span>
                    foo@bar
                  </li>
                  <li>
                    <span class="material-icons-outlined">pin_drop</span>
                    foo@bar
                  </li> -->
                </ul>
                <!-- <pre>{{currentAgent}}</pre> -->
                <template v-if="currentAgent.statistics">
                  <h4>Estatíticas de Atendimentos</h4>
                  <dl class="meta-statics">
                    <dt>Atendimentos ativos</dt>
                    <dd>
                      <strong>{{currentAgent.statistics.actives}}</strong>
                    </dd>
                    <dt>Total de chamadas</dt>
                    <dd>
                      <strong>{{currentAgent.statistics.total}}</strong>
                    </dd>
                    <dt>Tempo médio de atendimento</dt>
                    <dd>
                      <strong>{{currentAgent.statistics.time | roundTime}}</strong>
                    </dd>
                  </dl>
                </template>
                <div class="request-statistics" v-else>
                  <b-spinner variant="primary" type="grow" small class="m-2"></b-spinner>
                  <b-spinner variant="primary" type="grow" small class="m-2"></b-spinner>
                  <b-spinner variant="primary" type="grow" small class="m-2"></b-spinner>
                </div>
              </template>
              <div class="omni-placeholder-template" v-else>
                <p>
                  <span class="material-icons-outlined icon">privacy_tip</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card omni-conversations flex-grow-1" v-if="currentAgent !== null">
        <div class="d-flex flex-fill">
          <div class="omni-conversations-list d-flex flex-column" v-if="actualRoom === null">
            <header class="d-flex align-items-center">Atendimentos</header>
            <div class="flex-fill">
              <div class="omni-container-overflow">
                <!-- <template v-for="(item, index) in [...new Array(100)]"> -->
                <template v-for="(room, index) in currentAgentRooms">
                  <div class="omni-item" :key="index">
                    <aside
                      class="my-listing-actions"
                      v-if="room.status === 'QUEUE' && !$live.queuedEnabled">
                      <button
                        type="button"
                        class="decline"
                        @click="myListingAction($event, room, false)"
                        v-if="$live.acceptanceOption || isManager">
                        <i class="fas fa-times"></i>
                        <span class="sr-only">Declinar</span>
                      </button>
                      <button
                        type="button"
                        class="accept"
                        @click="myListingAction($event, room, true)"
                        v-if="$live.acceptanceOption && !isManager">
                        <i class="fas fa-check"></i>
                        <span class="sr-only">Aceitar</span>
                      </button>
                    </aside>
                    <button class="sub" :class="{ active: room === currentRoom }" @click="chooseRoom(room, null)">
                      <div class="d-flex align-items-center">
                        <div class="profile">
                          <div class="channel">
                            <span
                              class="material-icons-outlined"
                              v-if="room.channel === 'widget' || room.channel === 'widget-java'">public</span>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i
                              class="fab fa-whatsapp"
                              v-else-if="room.channel === 'whatsapp' || room.channel === 'whatsapp-java'"></i>
                            <i
                              class="fab fa-google"
                              v-else-if="room.channel === 'rcs' || room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <span class="material-icons-outlined" v-else-if="room.channel === 'email'">email</span>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                            <div class="sr-only">{{room.channel}}</div>
                          </div>
                          <span class="material-icons-outlined placeholder">person</span>
                        </div>
                        <div class="omni-room-info p-2 flex-fill">
                          <div class="name">
                            <template v-if="!room.contact.firstName && !room.contact.lastName">
                              <span class="badge bg-light">sem nome</span>
                            </template>
                            <template v-else>
                              {{room.contact.firstName}} {{room.contact.lastName}}
                            </template>
                          </div>
                          <div class="mt-2">
                            <!--  v-if="room.lastMessageType" -->
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <span class="material-icons-outlined">image</span> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <span class="material-icons-outlined">videocam</span> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <span class="material-icons-outlined">mic</span> Áudio
                              </template>
                              <template v-else>
                                <span class="material-icons-outlined">description</span> Arquivo
                              </template>
                            </span>
                            <span class="trimmed-text" v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                        </div>
                        <div class="omni-room-details align-self-start">
                          <p>
                            Status de Atendimento
                            <span class="badge ml-3" :class="{
                              'bg-success text-light': room.status === 'OPEN',
                              'bg-warning': room.status === 'CLOSE',
                              'bg-light': room.status === 'QUEUE',
                            }">
                              {{room.status | statusName}}
                            </span>
                          </p>
                          <p>
                            <span class="material-icons-outlined">incomplete_circle</span>
                            Sessão: {{currentTime - room.sessionTime | sessionMinutes}}
                            <span class="dot">.</span>
                            <template v-if="room.session === 'opened'">Aberta</template>
                            <template v-else-if="room.session === 'closing'">Encerrando</template>
                            <template v-else-if="room.session === 'closed'">Encerrada</template>
                          </p>
                          <p>
                            {{(room.lastMessageSentAt || room.createdAt) | relativeDate}}
                          </p>
                        </div>
                      </div>
                    </button>
                    <!-- <pre>x:{{room}}</pre> -->
                  </div>
                </template>
                <div class="omni-transcript-list">
                  <template v-if="$live.associativeTranscriptsTotal[fromGlobal]">
                    <template v-for="(room, index) in $live.associativeTranscriptsHistory">
                      <div
                        :key="index"
                        class="omni-item"
                        v-if="room.parentRoomId.indexOf(fromGlobal) === 0">
                        <button
                          class="sub"
                          :class="{ active: room === currentRoom }"
                          @click="chooseRoom(room, 'transcript')">
                          <div class="d-flex align-items-center">
                            <div class="profile">
                              <div class="channel">
                                <span
                                  class="material-icons-outlined"
                                  v-if="room.channel === 'widget' || room.channel === 'widget-java'">public</span>
                                <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                                <i
                                  class="fab fa-whatsapp"
                                  v-else-if="room.channel === 'whatsapp' || room.channel === 'whatsapp-java'"></i>
                                <i
                                  class="fab fa-google"
                                  v-else-if="room.channel === 'rcs' || room.channel === 'rcs-google'"></i>
                                <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                                <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                                <span class="material-icons-outlined" v-else-if="room.channel === 'email'">email</span>
                                <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                                <i class="fas fa-question" v-else></i>
                                <div class="sr-only">{{room.channel}}</div>
                              </div>
                              <span class="material-icons-outlined placeholder">person</span>
                            </div>
                            <div class="omni-room-info p-2 flex-fill">
                              <div class="name">
                                <template v-if="!room.contact.firstName && !room.contact.lastName">
                                  <span class="badge bg-light">sem nome</span>
                                </template>
                                <template v-else>
                                  {{room.contact.firstName}} {{room.contact.lastName}}
                                </template>
                              </div>
                              <div class="mt-2">
                                <!--  v-if="room.lastMessageType" -->
                                <span class="last-message-icon" v-if="room.lastMessageType">
                                  <template v-if="room.lastMessageType === 'image'">
                                    <span class="material-icons-outlined">image</span> Foto
                                  </template>
                                  <template v-else-if="room.lastMessageType === 'video'">
                                    <span class="material-icons-outlined">videocam</span> Vídeo
                                  </template>
                                  <template v-else-if="room.lastMessageType === 'audio'">
                                    <span class="material-icons-outlined">mic</span> Áudio
                                  </template>
                                  <template v-else>
                                    <span class="material-icons-outlined">description</span> Arquivo
                                  </template>
                                </span>
                                <span class="trimmed-text" v-html="$live.markdown(room.lastMessage)"></span>
                              </div>
                            </div>
                            <div class="omni-room-details align-self-start">
                              <p>
                                Status de Atendimento
                                <span class="badge ml-3 bg-warning">
                                 Encerrado
                                </span>
                              </p>
                              <p>
                                <span class="material-icons-outlined">incomplete_circle</span>
                                Sessão: {{currentTime - room.sessionTime | sessionMinutes}}
                                <span class="dot">.</span>
                                <template v-if="room.session === 'opened'">Aberta</template>
                                <template v-else-if="room.session === 'closing'">Encerrando</template>
                                <template v-else-if="room.session === 'closed'">Encerrada</template>
                              </p>
                              <p>
                                {{(room.lastMessageSentAt || room.createdAt) | relativeDate}}
                              </p>
                            </div>
                          </div>
                        </button>
                        <!-- <pre>x:{{room}}</pre> -->
                      </div>
                    </template>
                  </template>
                  <!-- <div v-if="!$live.associativeTranscriptsLoading[fromGlobal] && !$live.associativeTranscriptsTotal[fromGlobal]">
                    Não há histórico
                  </div> -->

                  <div class="omni-transcript-load" v-if="$live.associativeTranscriptsLoading[fromGlobal]">
                    <b-spinner variant="primary" type="grow" small></b-spinner>
                    <b-spinner variant="primary" type="grow" small></b-spinner>
                    <b-spinner variant="primary" type="grow" small></b-spinner>
                  </div>

                  <div class="omni-transcript-load" v-else-if="!$live.associativeTranscriptsNoMore[fromGlobal] && !$live.associativeTranscriptsLoading[fromGlobal]">
                    <button
                      class="live-transcript-load-more"
                      @click="$live.requestTranscripts(fromGlobal, currentAgent)">Carregar mais</button>
                  </div>
                  <!-- <p>
                    <strong>{{fromGlobal}}:</strong>
                  </p>

                  <p>
                    <strong>$live.associativeTranscriptsNoMore[fromGlobal]:</strong>
                    {{$live.associativeTranscriptsNoMore[fromGlobal]}}
                  </p>

                  <p>
                    <strong>$live.associativeTranscriptsLoading[fromGlobal]:</strong>
                    {{$live.associativeTranscriptsLoading[fromGlobal]}}
                  </p> -->
                </div>

                <div class="omni-placeholder-template" v-if="!currentAgentRooms.length && $live.associativeTranscriptsNoMore[fromGlobal] && !$live.associativeTranscriptsTotal[fromGlobal]">
                  <span class="material-icons-outlined icon">supervised_user_circle</span>
                </div>
              </div>
            </div>
          </div>
          <div class="omni-conversation d-flex" v-else>
            <OmniView
              ref="liveViewItem"
              @callLight="callLight"
              :roomId="currentRoom.roomId"
              :readOnly="currentRoom.status !== 'OPEN'" />
            <div class="live-meta p-2" :class="{ 'live-meta-show': metaShow }">
              <OmniInfo :room="currentRoom" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <b-modal ref="live-print-modal" title="Imprimir conversa" id="live-print-modal">
      <div class="iframe-content"><iframe srcdoc="&nbsp;" ref="live-print-content"></iframe></div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="primary" @click="requestPrint()">
          Imprimir
        </b-button>
      </template>
    </b-modal>
    <b-modal ref="live-send-file-modal" hide-footer title="Enviar arquivo" id="live-send-file-modal">
      <div class="form-group row">
        <div class="col-md-9">
          <input
            ref="uploadedFile"
            type="file"
            accept=".doc, .png, .jpeg, .jpg, .ogg, .webp, .doc, .docx, .zip, .exe, .pdf"
            hidden
            @change="uploadFile"
          />
          <div class="input-group mb-3">
            <div class="form-control">{{ form.file }}</div>
            <div class="input-group-append">
              <button
                class="btn btn-primary"
                type="button"
                @click="openFileUpload"
              >
                <template v-if="form.file">Trocar Arquivo</template>
                <template v-else>Selecionar Arquivo</template>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <button class="btn btn-success w-100" :disabled="isSending" @click="sendFile">Enviar</button>
        </div>
        <div class="col-md-12" v-if="uploadProgress > 0">
          <div class="progress">
            <div
              class="
                progress-bar
                progress-bar-striped
                progress-bar-animated
              "
              role="progressbar"
              :style="`width: ${uploadProgress}%;`"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ uploadProgress }}%
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <hr>
          <div class="alert alert-warning">Arquivos permitidos: jpeg, png, ogg, mp4 (apenas H.264), webp (max 100kb, 512x512), doc, pdf, zip</div>
          <hr>
        </div>
        <div class="col-sm-12">
          <h3 class="title is-3">Gravar Audio</h3>
          <p class="subtitle"><strong>Pressione</strong> no migrofone para gravar</p>
          <div class="row">
            <div class="col-sm-2">
              <!-- <VueRecordAudio @stream="onStream" :mode="'press'" @result="onResult"/> -->
            </div>
            <div class="col-sm-10">
              <div class="recorded-audio">
                <div class="recorded-item">
                  <div class="audio-container">
                    <audio :src="audioBlob" controls="controls" style="float: left; margin-top: 4px;"></audio>
                    <button
                      class="live-enter btn-send-audio"
                      @click="sendRecord"
                      v-if="readySend"
                    >
                      <span class="sr-only">Enviar mensagem</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="live-agents" hide-footer title="Selecione um agente">
      <!-- <div>{{$live.departments}}</div> -->
      <div class="live-transfer-list">
        <div class="qt-loader qt-loader-mini" v-if="$live.agentsLoading">
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
        </div>
        <div class="p-20" v-else-if="!$live.agents.length">
          Não há outros agentes online
        </div>
        <b-list-group v-else>
          <template v-for="agent of $live.agents">
            <b-list-group-item button :key="agent.id" @click="transferToAgent(agent.id)">
              Transferir para <strong>{{agent.name}}</strong>
            </b-list-group-item>
          </template>
        </b-list-group>
      </div>
    </b-modal>
    <b-modal ref="live-departments" hide-footer title="Selecione um departamento">
      <!-- <div>{{$live.departments}}</div> -->
      <div class="live-transfer-list">
        <div class="qt-loader qt-loader-mini" v-if="$live.departmentsLoading">
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
        </div>
        <div class="p-20" v-else-if="!$live.departments.length">
          Não há departamentos cadastrados
        </div>
        <b-list-group v-else>
          <!-- <template v-for="department of $live.departments"> -->
          <template v-for="department of $live.departments">
            <b-list-group-item button :key="department.id" @click="transferToDepartment(department.id)">
              Transferir para <strong>{{department.name}}</strong>
            </b-list-group-item>
          </template>
        </b-list-group>
      </div>
    </b-modal>

    <!-- -->

    <div class="live-light" v-if="messageInfo">
      <div class="live-light-header">
        <div class="live-light-header-info">
          <div class="l-image">
            <i class="fas fa-user"></i>
          </div>
          <div class="user-info-header">
            <strong>{{messageInfo.username}}</strong>
            <span :title="messageInfo.sentAt">{{messageInfo.sentAt | roundTime}}</span>
          </div>
        </div>
        <div class="live-light-header-close">
          <a class="c-pointer" style="margin-right: 20px" :href="messageInfo.uri" target="_blank">
            <svg viewBox="0 0 24 24" width="24" height="24" class=""><path fill="#000" d="M18.9 10.3h-4V4.4H9v5.9H5l6.9 6.9 7-6.9zM5.1 19.2v2H19v-2H5.1z"></path></svg>
          </a>
          <a class="c-pointer" style="margin-right: 20px" @click="messageInfo = null">
            <svg viewBox="0 0 24 24" width="24" height="24" class=""><path fill="#000" d="M19.8 5.8l-1.6-1.6-6.2 6.2-6.2-6.2-1.6 1.6 6.2 6.2-6.2 6.2 1.6 1.6 6.2-6.2 6.2 6.2 1.6-1.6-6.2-6.2 6.2-6.2z"></path></svg>
          </a>
        </div>
        <div style="clear:both"></div>
      </div>
      <div class="live-light-body">
        <img :src="messageInfo.uri" class="img-fluid" style="max-height: 500px;"/>
        <div class="live-light-left">
          <a class="live-light-close c-pointer" style="text-align: center;" @click="backMedia">
            <svg viewBox="0 0 30 30" width="30" height="30" style="margin: 0 auto;"><path fill="#fff" d="M19.214 21.212L12.865 15l6.35-6.35-1.933-1.932L9 15l8.282 8.282 1.932-2.07z"></path></svg>
          </a>
        </div>
        <div class="live-light-right">
          <a class="live-light-close c-pointer" style="text-align: center;" @click="nextMedia">
            <svg viewBox="0 0 30 30" width="30" height="30" style="margin: 0 auto;"><path fill="#fff" d="M11 21.212L17.35 15 11 8.65l1.932-1.932L21.215 15l-8.282 8.282L11 21.212z"></path></svg>
          </a>
        </div>
        <hr style="margin-bottom: 5px;">
        <div class="list-gallery" style="height: 80px; ">
          <div style="width: 8%" v-for="(message, i) in messagesMidia" class="list-gallery-item" :key="`galery-light_list-item${i}`" >
            <div
              :key="`message_item_${message.id}`"
              class="list-gallery-image"
              v-if="message.roomId === currentRoom.roomId"
            >
              <div class="list-gallery-image-sub">
                <div class="list-gallery-image-sub-item">
                  <div class="list-gallery-image-sub-imagem" :style="`background-image: url('${message.uri}')`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="live-popup-item"
      :class="{
        audio: popupFile && popupFile.type === 'audio',
        video: popupFile && popupFile.type === 'video',
      }"
      v-dragglable="popupFile"
      v-if="popupFile">
      <button @click="popupFile = null">
        <span class="sr-only">Fechar popup</span>
        <i class="fas fa-times"></i>
      </button>
      <header>
        <i class="fas fa-ellipsis-h"></i>
      </header>
      <video
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-if="popupFile.type === 'video'"></video>
        <!-- :type="popupFile.media_type" -->
      <audio
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-else-if="popupFile.type === 'audio'"></audio>
        <!-- :type="popupFile.media_type" -->
    </div>

    <vue-easy-lightbox
      :visible="galleryURI !== null"
      :imgs="galleryURI"
      :index="galleyIndex"
      @hide="galleryHide" />
  </div>
</template>

<script>
import OmniView from '@/views/rebranding/live/Conversation.vue';
import OmniInfo from '@/views/rebranding/live/Info.vue';
import Permissions from '@/router/permissions';
import Swal from 'sweetalert2';
import GoogleBusinessIcon from '@/components/GoogleBusinessIcon.vue';
import BucketService from '@/services/bucket.service';
import PageHeader from '@/components/rebranding/PageHeader.vue';

export default {
  name: 'Live',
  components: {
    OmniView,
    OmniInfo,
    GoogleBusinessIcon,
    PageHeader,
  },
  mounted() {
    if (Permissions.isRole(this.$store.state.account.role, '/live/manage')) {
      console.log('IndexManager');
      this.$live.setup(this);
    } else {
      this.$router.push('/403');
    }

    setInterval(() => {
      if (this.$live.ready) this.currentTime = new Date();
    }, 1000);
  },
  data() {
    return {
      currentAgent: null,
      currentRoom: null,
      currentTime: 0,
      fromTranscript: false,
      metaShow: false,

      currentTab: null,

      widget: 0,
      audioBlob: null,
      readySend: false,
      isSending: false,
      uploadProgress: 0,
      form: {},
      formData: {},
      data: {},
      dataAudio: null,
      messagesMidia: [],
      indexMidia: 0,
      messageInfo: null,
      oldRoom: null,
      oldMessages: [],

      popupFile: null,
      galleryURI: null,
      galleyIndex: -1,

      // fallback, remover no futuro
      isManager: true,
    };
  },
  computed: {
    fromGlobal() {
      if (!this.currentAgent?.id) return '';

      return `global:${this.currentAgent.id}`;
    },
    currentName() {
      return this.actualRoom?.username;
    },
    actualRoom() {
      return this.currentRoom;
    },
    availableAgents() {
      if (!this.$live.ready) return [];

      const agents = this.$live.managedAgents;
      const myUserId = this.$live.myUserId;

      if (!agents || !myUserId) return [];

      return agents.filter((agent) => agent.status === 'AVAILABLE' && agent.id !== myUserId);

      // .sort((a, b) => {
      //   const result = new Date(a.lastUpdate) - new Date(b.lastUpdate);

      //   if (result > 0) return -1;

      //   if (result < 0) return 1;

      //   return 0;
      // });
    },
    notAvailableAgents() {
      const agents = this.$live.managedAgents;
      const myUserId = this.$live.myUserId;

      if (!agents || !myUserId) return [];

      return agents.filter((agent) => agent.status !== 'AVAILABLE' && agent.id !== myUserId);

      // .sort((a, b) => {
      //   const result = new Date(a.lastUpdate) - new Date(b.lastUpdate);

      //   if (result > 0) return -1;

      //   if (result < 0) return 1;

      //   return 0;
      // });
    },
    currentAgentRooms() {
      this.$live.historyRefresh;

      const id = this.currentAgent?.id;

      if (!id) return [];

      const queuedEnabled = this.$live.queuedEnabled;

      return this.$live.history.filter((room) => {
        if (room.roomId.indexOf('spy:') !== 0) return false;

        // const agentId = room.agent_id || room.agentId;

        // return room.status === 'OPEN' && (room.agent_id === id || room.agentId === id);

        return (room.agent_id === id || room.agentId === id) && (
          room.status === 'OPEN' || (room.status === 'QUEUE' && !queuedEnabled)
        );
      });
    },
    hasFiles() {
      const room = this.oldRoom ?? this.currentRoom;

      if (!room) return false;

      const messages = this.fromTranscript ? this.$live.associativeTranscriptsMessages : this.$live.messages;

      if (!messages || !messages.length) return false;

      const roomId = room.roomId;

      for (let i = 0, j = messages.length; i < j; i += 1) {
        const msg = messages[i];
        if (msg.roomId === roomId && msg.attachments.length) return true;
      }

      return false;
    },
    currentFiles() {
      const room = this.oldRoom ?? this.currentRoom;

      if (!room) return null;

      const messages = this.fromTranscript ? this.$live.associativeTranscriptsMessages : this.$live.messages;

      if (!messages || !messages.length) return null;

      const attachments = {};
      const roomId = room.roomId;

      messages.forEach((message) => {
        if (message.roomId === roomId && message.attachments.length) {
          message.attachments.forEach((attachment) => {
            const type = attachment.type;

            if (!attachments[type]) attachments[type] = [];

            attachments[type].push({
              uri: attachment.uri,
              name: attachment.name,
            });
          });
        }
      });

      return attachments;
    },
    availableRoomTags() {
      const currentTags = this.currentRoom?.tags;

      const accountTags = this.$live.accountTags;

      if (!currentTags || accountTags.length === 0) return accountTags;

      return accountTags.filter((tag) => tag !== null && tag !== '' && typeof tag !== 'undefined' && currentTags.indexOf(tag) === -1);
    },
    roomClosed() {
      return this.currentRoom.status !== 'OPEN' && !!this.currentRoom.closedAt;
    },
  },
  filters: {
    roundTime: (value) => {
      if (Number.isNaN(value) || !value || value.indexOf(':') === -1) return '';

      const [h, m, s] = value.substring(0, 8).split(':');

      value = (h * 60) + (m * 1);

      const days = Math.floor(value / 1440);

      // console.log('roundTime.days', days);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      // console.log('roundTime.hours', hours);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      // console.log('roundTime.mins', value);

      return `${Math.floor(value)} min${value < 2 ? '' : 's'}`;
    },
    sessionMinutes(time) {
      if (!time) return '-';

      const value = time / 1000 / 60;

      const days = Math.floor(value / 1440);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      return `${Math.floor(value)} minuto${value < 2 ? '' : 's'}`;
    },
    statusName(status) {
      switch (status) {
        case 'OPEN':
          status = 'Aberto';
          break;
        case 'CLOSE':
          status = 'Encerrado';
          break;
        case 'QUEUE':
          status = 'na fila';
          break;
        default:
          status = '-';
      }

      return status;
    },
  },
  watch: {
    currentAgent(newVal) {
      this.currentRoom = null;
    },
    currentRoom(newVal) {
      if (!newVal) return;

      console.log('watch:currentRoom');

      if (newVal.tabId) {
        this.currentTab = newVal.tabId;
      } else {
        this.currentTab = null;
      }

      // this.currentRoomTags = newVal?.tags ?? [];

      this.currentRoomTags = newVal.tags;

      const currentTags = this.currentRoom.tags;

      console.log('watch:currentRoom', { newVal, currentTags });
    },
    currentTab(newVal) {
      console.log('omni:currentTab');

      const room = this.oldRoom ?? this.currentRoom;

      if (!room) return;

      console.log('watch:currentTab', newVal, room.tabId);

      if (newVal !== room.tabId) this.$live.changeTabRoom(room, newVal);
    },
  },
  methods: {
    showAgents() {
      if (this.$refs['live-agents']) {
        this.$live.requestAgents(this.currentAgent?.id);
        this.$refs['live-agents'].show();
      } else {
        console.error('$refs[live-agents] is invalid');
      }
    },
    showDepartments() {
      if (this.$refs['live-departments']) {
        this.$live.requestDepartments();
        this.$refs['live-departments'].show();
      } else {
        console.error('$refs[live-departments] is invalid');
      }
    },
    closeMeta() {
      this.metaShow = false;
    },
    chooseAgent(agent) {
      console.log('chooseAgent#1', this.currentAgent !== agent, this.currentAgent, agent);

      if (this.currentAgent !== agent) {
        const lastAgent = this.currentAgent;

        // Observa as salas do agente atual
        this.currentAgent = agent;
        this.$live.subscribeAgentRooms(agent);

        // Se existir um agente anterior deverá fechar o observador para este
        if (lastAgent) this.$live.subscribeAgentRooms(lastAgent, false);

        console.log('chooseRoom:1');

        if (this.fromGlobal !== '' && this.currentAgent) {
          this.$live.requestTranscripts(this.fromGlobal, this.currentAgent);
        }
      }
    },
    closeAgent(onlyRoom = false) {
      console.log('closeAgent', onlyRoom, this.currentAgent);

      if (this.currentAgent) {
        // this.$live.subscribeAgentRooms(this.currentAgent, false);
        const room = this.currentRoom;

        if (onlyRoom === false) this.currentAgent = null;

        this.currentRoom = null;

        if (room) this.$live.unsubscribeRoom(room);
      }
    },
    // chooseRoom(room) {
    //   this.currentRoom = room;
    // },
    chooseRoom(room, fromTranscript) {
      console.log(':chooseRoom', room, ':newMessages', this.$live.newMessages);

      if (room?.status === 'QUEUE') return;

      this.closeAgent(true);

      if (room) {
        this.editNameValue = room.username;

        if (room?.contact?.mobileNumber) this.editPhoneValue = this.$live.maskPhone(room.contact.mobileNumber);

        if (room.email) this.editMailValue = room.email;

        if (fromTranscript !== null) {
          this.$live.requestTranscriptMessages(room, fromTranscript === 'idle');
        } else if (!room.fromOffline && !room.fromIdle) {
          this.$live.open(room, null, true);
          this.$live.requestHistoricRoom(room.roomId);
        }
      }

      // this.messagesMidia = [];

      // this.$live.messages.forEach((element) => {
      //   if (element && room) {
      //     if ((element.roomId === room.roomId)) {
      //       switch (element.mediaType) {
      //         case 'image/jpeg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/png':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/jpg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/webp':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/gif':
      //           this.messagesMidia.push(element);
      //           break;
      //         default:
      //           break;
      //       }
      //     }
      //   }
      // });

      this.currentRoom = room;
      this.fromTranscript = fromTranscript;
    },
    callLight(x) {},
    editOpen() {
      this.editFieldsStash = [...this.editFields];
      this.editMode = true;
    },
    editSave() {
      // this.editFieldsStash = [...this.editFields];
    },
    editCancel() {
      this.editFields = this.editFieldsStash;
      this.editMode = false;
    },

    requestPrint() {
      this.$refs['live-print-content'].contentWindow.print();
    },
    async print() {
      if (this.currentRoom) {
        const modal = this.$refs['live-print-modal'];
        const username = this.currentRoom.username;

        let roomId;
        let messages;

        if (this.oldRoom) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.oldRoom.roomId;
        } else if (this.fromTranscript) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.currentRoom.roomId;
        } else {
          messages = this.$live.messages;
          roomId = this.currentRoom.roomId;
        }

        if (!modal) {
          console.error('this.$refs[livePrintModal] not found');
          return;
        }

        await modal.show();

        // console.log('modal', modal);

        const frame = this.$refs['live-print-content'];

        // let content = '<html><head><style>p{border-top:thin solid #e9e8e8;margin:.4em 0 .8em;padding:.4em 0}p+div{margin:.4em 0;clear:both}code{overflow-wrap:break-word;white-space:pre-wrap;word-break:break-word}p>strong{float:left}p>time{float:right;font-size:.7em}</style><style media="print">button{display:none}</style></head><body><button onclick="print()">Imprimir</button>';

        let content = `<html><head><style>
        html{padding:0;margin:0}body{padding:1.2em;margin:0;font-size:.81em}
        .print-name{font-weight:bolder;float:left;width:12em;}
        .print-msg{border-bottom:thin solid #ececec;padding:.3em 0;page-break-inside:avoid;break-inside:avoid}
        .print-msg:last-child{border:none}
        .print-time{display:block;margin:.5em 0 1em 0;text-align:right;font-size:.96em}
        .print-inner{margin:0 0 0 12em}
        .print-msg.agent .print-name{color:#d90505}
        </style>
        </head><body>`;

        for (let i = 0, j = messages.length; i < j; i += 1) {
          const msg = messages[i];

          if (msg.roomId === roomId) {
            let username = '';

            if (msg.username) {
              username = msg.username;
            } else if (msg.from && msg.from.username) {
              username = msg.from.username;
            }

            let message;

            if (msg.attachments?.length) {
              message = `<em>&lt;${msg.attachments[0].name}&gt;</em>`;
            } else {
              message = this.$live.markdown(msg.message, true);
            }

            content += `<div class="print-msg ${msg.typeUser === 'AGENT' ? 'agent' : ''}">
            <strong class="print-name">
            ${msg.automatic ? '<automática>' : username}
            ${msg.typeUser === 'AGENT' ? ' (agente)' : ''}:
            </strong>
            <p class="print-inner">
            ${message}
            <time class="print-time">
            ${new Date(msg.sentAt).toLocaleString('pt-BR')}
            ${msg.status === 'FAIL' ? ' (não entregue)' : ''}
            </time>
            </p>
            </div>`;
          }
        }

        content += '</body></html>';

        frame.srcdoc = content;
      }
    },
    sendFile() {
      this.isSending = true;
      this.formData.append('account_id', this.$live.contextApp.$store.state.account.id);
      this.formData.append('visibility', 'public');
      Promise.all([
        BucketService.uploadFile(this.formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.uploadProgress = 0;
            const extension = responses[0].data.extension;
            let media = 'image/jpeg';

            switch (extension) {
              case 'ogg':
                media = 'audio/ogg';
                break;
              case 'mp4':
                media = 'video/mp4';
                break;
              case 'mpeg':
                media = 'video/mpeg';
                break;
              case 'webp':
                media = 'image/webp';
                break;
              case 'midi':
                media = 'audio/midi';
                break;
              case 'mp3':
                media = 'audio/mp3';
                break;
              case 'zip':
                media = 'application/zip';
                break;
              case 'pdf':
                media = 'application/pdf';
                break;
              case 'xml':
                media = 'application/xml';
                break;
              case 'jpeg':
                media = 'image/jpeg';
                break;
              case 'jpg':
                media = 'image/jpg';
                break;
              case 'png':
                media = 'image/png';
                break;
              default:
            }

            const draft = {
              roomId: this.currentRoom.roomId,
              channel: this.currentRoom.channel,
              type: 'file',
              uri: responses[0].data.url,
              mediaType: media,
            };

            this.$live.emit('message', draft);
            this.$refs['live-send-file-modal'].hide();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const reader = new FileReader();
      this.importing = true;
      this.formData = new FormData();
      const file = event.target.files[0];
      this.formData.append('file', file);
      this.form.file = file.name;
      this.$forceUpdate();
    },

    galleryShow(url) {
      // this.galleryURI = url; // [url];
      this.galleryURI = [
        { src: url },
      ];
      this.galleyIndex = 0;
    },
    galleryHide() {
      this.galleryURI = null;
    },
    async showFile(file, type) {
      switch (type || file.type) {
        case 'image':
          this.galleryShow(file.uri);
          break;
        default:
          this.popupFile = file;

          if (!file.type) this.popupFile.type = type;

          this.tryAutoPlay(0);
      }
    },
    async tryAutoPlay(retry) {
      const media = this.$refs.mediaInteractive;

      console.log('tryAutoPlay', media);

      if (retry > 5) return;

      if (media) {
        await this.delay(500);
        media.pause();
        media.play();
      } else {
        await this.delay(500);
        this.tryAutoPlay(retry + 1);
      }
    },
    async delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },

    updateTags() {
      this.$live.updateTags(this.currentRoom);
    },

    finish() {
      const room = this.currentRoom;

      console.log('finish', room);

      if (room && room.roomId) {
        Swal.fire({
          title: 'Finalizar conversa',
          text: 'Tem certeza que gostaria de finalizar essa conversa?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) {
            // this.chooseRoom(null, false);
            this.$live.close(room);
          }
        });
      }
    },
    associativeHistory() {
      if (this.currentRoom) {
        this.$live.requestTranscripts(this.currentRoom);
      }
    },
    transferToAgent(id) {
      const room = this.currentRoom;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.currentRoom = null;
      //   this.$live.transferToAgent(room, id);
      //   this.$refs['live-agents'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToAgent(room, id);
          this.$refs['live-agents'].hide();
        }
      });
    },
    transferToDepartment(id) {
      const room = this.currentRoom;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.currentRoom = null;
      //   this.$live.transferToDepartment(room, id);
      //   this.$refs['live-departments'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToDepartment(room, id);
          this.$refs['live-departments'].hide();
        }
      });
    },
    transferToQueue() {
      const room = this.currentRoom;

      Swal.fire({
        title: 'Devolver a fila',
        text: 'Deseja devolver o cliente para fila?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToQueue(room);
        }
      });
    },
    myListingAction(event, room, accept) {
      console.log('myListingAction', room.status);

      if (event?.target?.blur) event.target.blur();

      if (room.status !== 'QUEUE') return;

      console.log('myListingAction', room.status, accept);

      if (accept) {
        if (!this.isManager) this.chooseRoom(room, null);
      } else {
        Swal.fire({
          title: 'Rejeitar',
          text: 'Tem certeza que deseja rejeitar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) this.$live.rejectRoom(room);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.omni-spy::v-deep .page-header {
  padding: 0 !important;

  h1 {
    height: 60px;
  }
}

.live-transcript-load-more {
  border-radius: 36px;
  background: #fff;
  border: 2px solid #0E1421;
  color: #0E1421;
  margin: 1.5rem 0;
  padding: .7rem 2rem;
  font-size: .82rem;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.live-popup-item {
  position: fixed;
  left: 70px;
  bottom: 4px;
  z-index: 1000;
  background: rgba(0,0,0,.7);
  display: none;
  color: #fff;
  border-radius: .2em;
  width: 280px;

  @media (max-width: 992px) {
    left: 4px;
  }

  & > header {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    color: #fff;
    font-weight: bold;

    i, i::before { color: #fff; }
  }

  & > button {
    background: rgba(0,0,0,.5);
    border-radius: 0 4px 4px 0;
    border: none;
    position: absolute;
    z-index: 1003;
    right: -32px;
    top: 16px;
    font-size: 12pt;
    text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &, & > i {
      color: #fff;
      padding: 0;
      margin: 0;
    }
  }

  .live-popup-sub {
    position: relative;
    z-index: 1001;
    overflow: hidden;
  }

  &.audio {
    display: block;
    height: 72px;

    audio {
      margin-left: 2%;
      width: 96%;
    }
  }

  &.video {
    display: block;
    height: 180px;

    video {
      width: 100%;
      height: 100%;
    }
  }

  video, audio {
    position: relative;
    z-index: 1002;
  }
}

#live-print-modal .iframe-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::v-deep #live-print-modal .modal-dialog {
  @media (min-width: 576px) {
    max-width: 90%;
  }

  min-width: 240px;

  .modal-content {
    min-height: 210px;
    height: 80vh;

    .modal-body {
      padding: 0;
    }

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
}

.live-meta {
  flex: 0 0 350px !important;
  border-left: thin solid rgba(0, 0, 0, 0.08);
  display: none;
  max-width: 350px;

  &.live-meta-show {
    display: block;
  }
}

.trimmed-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 200px;
  vertical-align: middle;
}

.dot {
  display: inline-block;
  font-size: 0;
  height: .32rem;
  width: .32rem;
  border-radius: .32rem;
  background-color: #752de6;
  margin: 0 1rem;
}

.omni-spy {
  padding: 3rem 0 0 1.5rem;
  height: calc(100vh - 7rem);
  max-height: calc(100vh - 7rem);

  h1 {
    margin-bottom: 1rem;
  }

  .flex-fill {
    height: 100%;
  }

  .omni-main {
    height: calc(100% - 10px);
  }
}

.omni-main {
  width: 98%;
  gap: .5rem;

  h4 {
    font-size: .82rem;
    font-weight: normal;
    margin-bottom: 2rem;
  }

  .meta-info {
    list-style-type: none;
    color: #c2c2c2;

    &, li {
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 1rem;

      & > span {
        color: var(--clr-yup-purple);
        margin-right: .5rem;
      }
    }
  }

  .meta-statics {
    color: #c2c2c2;
    font-size: .82rem;

    dt {
      font-weight: normal;
      margin-bottom: .5rem;
    }

    dd {
      color: #000;
    }
  }
}

.last-message-icon {
  span {
    vertical-align: middle;
  }
}

.omni-room-info {
  text-align: left;
  font-size: .72rem;
}

.omni-room-details {
  text-align: right;
  min-width: 208px;

  p {
    margin-bottom: .2rem;
  }

  * {
    vertical-align: middle;
  }
}

.omni-status {
  font-size: .72rem;
  padding: .62rem;
}

.omni-transcript-load {
  padding: .2rem;
  text-align: center;

  &::v-deep .spinner-grow {
    margin: .2rem;
  }
}

.omni-item {
  position: relative;
  overflow: hidden;
  padding: 0;

  &, div, p {
    font-size: .72rem;
  }

  .material-icons-outlined {
    color: #752de6 !important;
  }

  .name {
    font-weight: bold;
  }

  .time-agent {
    position: absolute;
    right: 0;
    top: 0;
    padding: .72rem;
    color: #752de6 !important;
    font-size: .72rem;

    span {
      vertical-align: middle;
      font-size: .92rem;
    }
  }

  .profile {
    min-width: 52px;
    width: 52px;
    height: 52px;
    border-radius: 26px;
    background-color: #cfcfcf;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .placeholder {
      font-size: 1.5rem;
    }

    .notify, .channel {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center;
    }

    .notify {
      background-color: #c1c1c1;
      font-size: .52rem;
      min-width: .92rem;
      height: .92rem;
      border-radius: .46rem;

      &, span {
        color: #fff;
      }

      span {
        font-size: .62rem;
      }
    }

    .channel {
      font-size: .72rem;
      background-color: #fff;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: .55rem;
    }
  }

  & > .sub {
    background-color: transparent;
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 82px;
    transition: .2s all ease;
    outline: none;
  }

  &:hover > .sub, & > .sub.active {
    background-color: rgba(0, 0, 0, .08);
  }

  .my-listing-actions {
    padding: 5px;
    position: absolute;
    width: 80px;
    bottom: 0;
    right: 0;
    white-space: nowrap;
    transition: all .2s ease-out;
    text-align: right;
    transform: translateX(100%);

    & ~ .sub {
      pointer-events: none;
    }

    button {
      border: none;
      background: #ccc;
      color: #fff;
      width: 32px;
      height: 32px;
      margin: .2rem;
      border-radius: 32px;

      i {
        color: inherit;
        pointer-events: none;
      }
    }

    button.accept {
      background: #6eda2c;
    }

    button.decline {
      background: #f00;
    }
  }

  &:hover {
    .my-listing-actions {
      transform: translateX(0) !important;
    }
  }
}

.omni-placeholder-template {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .icon {
    font-size: 4rem;
    color: rgba(0, 0, 0, .32);
  }
}

.omni-container-overflow {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

// .omni-agents, .omni-conversations {
//   max-height: calc(100% - 240px);
// }

.omni-agent {
  flex: 0 0 320px !important;

  .request-statistics {
    text-align: center;
  }

  .profile {
    margin-bottom: 1rem;

    h4 {
      font-size: 1.2rem;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .photo {
      background-color: #ccc;
      border-radius: 2rem;
      height: 4rem;
      width: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      pointer-events: none;
    }

    .info {
      padding-left: 1rem;
    }
  }
}

.omni-agents-list, .omni-conversations-list {
  & > header {
    border-bottom: thin solid rgba(0, 0, 0, .08);
    height: 82px;
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: bold;
  }
}

.omni-agents {
  min-width: 620px;
  width: 620px;

  .omni-agents-list {
    border-right: thin solid rgba(0, 0, 0, .08);
    min-width: 300px;
    width: 300px;

    .flex-fill {
      overflow: auto;
    }
  }

  .omni-agent-profile {
  }
}

.omni-conversations {
  .omni-item {
    .sub {
      padding: 1rem 2rem;
    }
  }

  .omni-conversations-list {
    .flex-fill {
      overflow: auto;
    }
  }
}

.omni-conversation, .omni-conversations-list {
  width: 100%;
  height: 100%;
}
</style>
